import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"




const NavItem = styled(Link)`
  text-decoration: none;
  color: #f5f5f5;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 5px 0;
    z-index: 6;
    
  }
`

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `0.2rem`, fontSize:`0.8rem` }}>
    <NavItem to={props.to}>{props.children}</NavItem>
  </li>
)
// highlight-end

export default function NavLocale() {
  return (
    <div style={{ margin: `0rem`, maxWidth: 300, padding: `0 1rem`  }}>

        <ul style={{ listStyle: `none`, float: `right`   }}>
          <ListLink to="/">Es</ListLink>
          <ListLink to="/en_index/">En</ListLink>
        </ul>

    </div>
  )
}